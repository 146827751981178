<template>
  <v-container fluid class="grey lighten-3">

    <v-card>
      <v-card-title>
        {{ name }}
      </v-card-title>

      <v-card-text>
        <v-container fluid>

          <v-form ref="form">
            <v-autocomplete v-model="item.verticals_fk" :items="verticals" item-value="id" item-text="name" label="Vertical"
              required :rules="[v => !!v || 'Vertical is required']"></v-autocomplete>

            <v-autocomplete v-model="item.countries_fk" :items="countries" item-value="id" item-text="iso2" label="Country"
              required :rules="[v => !!v || 'Country is required']"></v-autocomplete>

            <v-autocomplete v-model="item.languages_fk" :items="languages" item-value="id" item-text="iso2" label="Language"
              required :rules="[v => !!v || 'Language is required']"></v-autocomplete>

            <v-text-field v-model="item.keyword" label="Keyword" required :rules="[v => !!v || 'Keyword is required']"></v-text-field>

          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="pl-5 pb-5">
        <v-btn class="mr-2" :to="{ name: 'Keyword collector' }">Back</v-btn>
        <v-btn color="primary" :loading="saving" @click="save()">Save</v-btn>

        <v-fade-transition mode="out-in">
          <v-btn color="green darken-1" text v-show="showSaved">Saved</v-btn>
        </v-fade-transition>

      </v-card-actions>

    </v-card>

  </v-container>
</template>

<script>
import formsMixin from '@/mixins/forms'

export default {
  name: 'KeywordCollectorBlacklistDetail',

  props: {
    id: { type: Number, required: true },
  },

  data () {
    return {
      item: {
        verticals_fk: null,
        countries_fk: null,
        languages_fk: null,
        keyword: null,
      },
      valid: false,
      loading: false,
      saving: false,
      showSaved: false,
    }
  },

  computed: {
    countries () {
      return this.$store.state.common.countries
    },

    dataEndpoint () {
      return '/a/core/keyword-ideas-blacklist/' + this.id
    },

    languages () {
      return this.$store.state.common.languages
    },

    name () {
      return 'ID #' + this.id
    },

    verticals () {
      return this.$store.state.core.verticals
    },
  },

  components: {
  },

  mixins: [formsMixin],

  methods: {
    fetchData: function () {
      this.loading = true

      // reset data (to bring back deleted item fields)
      Object.assign(this.$data, this.$options.data.apply(this))

      var myURL = this.dataEndpoint + '?xfields='
      // only get the fields needed to fill the item object
      myURL = this.forms_addItemFieldsToURI(myURL, this.item, ['vertical'])

      this.$http.get(myURL).then(resp => {
        this.item = resp.data.data
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    save: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      this.saving = true

      // only send the required item fields to API
      delete this.item['id']
      delete this.item['vertical']
      delete this.item['entry_at']
      delete this.item['entry_by']
      delete this.item['last_modified_at']

      var body = JSON.stringify(this.item)
      this.$http.put(this.dataEndpoint, body).then(resp => {
        this.showSaved = true
        this.timer = setTimeout(() => { this.showSaved = false }, 3000)
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: this.dataEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.saving = false)
    },
  },

  created: function () {
    this.fetchData()
  },
}
</script>
